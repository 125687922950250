import "./App.css";
import headshot from "./assets/headshot.png";
import React from "react";
import {
  BrowserRouter as Router,
  Routes,
  NavLink,
  Route,
} from "react-router-dom";
import Home from "./components/Home";
import About from "./components/About";
import Portfolio from "./components/Portfolio";

export default function App() {
  return (
    <Router>
      <div>
        <div className="flex justify-center pb-12 pt-5">
          <img
            className="max-w-sm"
            src="https://see.fontimg.com/api/renderfont4/8M89J/eyJyIjoiZnMiLCJoIjoxMzAsInciOjIwMDAsImZzIjo2NSwiZmdjIjoiIzAwMDAwMCIsImJnYyI6IiNGRkZGRkYiLCJ0IjoxfQ/SmVzc2ljYURvbmc/southern-jannie.png"
            alt="Calligraphy fonts"
          />
        </div>
        <div className="nav flex justify-center my-10">
          <NavLink
            className={({ isActive }) =>
              isActive ? "mx-5 uppercase selected" : "mx-5 uppercase"
            }
            to="/"
          >
            Home
          </NavLink>
          <NavLink
            className={({ isActive }) =>
              isActive ? "mx-5 uppercase selected" : "mx-5 uppercase"
            }
            to="./about"
          >
            About
          </NavLink>
          <NavLink
            className={({ isActive }) =>
              isActive ? "mx-5 uppercase selected" : "mx-5 uppercase"
            }
            to="/portfolio"
          >
            Portfolio
          </NavLink>
          <a className="mx-5 uppercase" href="mailto:jscdng@gmail.com">
            Contact
          </a>
        </div>
        <div className="flex sm:flex-row flex-col items-center sm:items-start sm:justify-start">
          <div className="font-bold sm:font-normal sm:w-1/3 my-5 flex flex-col items-center sm:items-end mx-5 justify-center">
            <img
              className="headshot my-3"
              src={headshot}
              alt="Picture of Jessica"
            />
            <p className="mr-3">Jessica Dong</p>
            <p className="mr-3">MA English and Film Studies</p>
            <p className="mr-3">BA (Hons) English Literature</p>
          </div>
          <Routes>
            <Route exact path="/about" element={<About />} />
            <Route exact path="/" element={<Home />} />
            <Route exact path="/portfolio" element={<Portfolio />} />
          </Routes>
        </div>
      </div>
    </Router>
  );
}
