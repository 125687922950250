import React from "react";
import GridPhoto from "./GridPhoto";
import AssetFetcher from "../helpers/AssetFetcher";

export default function Portfolio() {
  return (
    <div class="w-2/3 flex justify-center mb-5">
      <div class="grid gap-4 xl:gap-24 sm:grids-cols-1 md:grid-cols-2">
        <GridPhoto
          imgSrc={AssetFetcher.WHO}
          overlayText="World Health Organization"
        />
        <GridPhoto imgSrc={AssetFetcher.WATER_BOTTLE} overlayText="Nestlé" />
        <GridPhoto imgSrc={AssetFetcher.CAFE} overlayText="The Gen Z Era" />
        <GridPhoto imgSrc={AssetFetcher.FORMULA_E} overlayText="Formula E" />
        <GridPhoto imgSrc={AssetFetcher.MOTORCYCLE} overlayText="KTM" />
        <GridPhoto imgSrc={AssetFetcher.METAVERSE} overlayText="Metaverse" />
      </div>
    </div>
  );
}
