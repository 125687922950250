import React from "react";

export default function Home() {
  return (
    <div class="w-2/3 mx-10">
      <p>Hi, I'm Jessica.</p>
      <br />
      <p>
        I'm an experienced B2B & B2C copywriter and content writer based in
        Toronto, Ontario.
      </p>
      <br />
      <p>
        A proud Wilfrid Laurier Alumna, I have a Master of Arts in English
        Literature and Film Studies.
      </p>
      <br />
      <p>
        My professional work, driven by competitor analyses, consumer
        research, and creativity, aims to help SaaS brands differentiate themselves
        from the rest of the market. I find fulfillment in crafting witty,
        compelling copy that stimulate emotional responses and spark
        curiosity–in other words, I write words that speak to the
        heart and tickle the brain.
      </p>
      <br />
      <p>
        If you think I’d be a good fit for your brand, feel free to shoot me a
        message.
      </p>
      <p>Let’s create some amazing work together! </p>
      <br />
      <br />
      <p>
        TL;DR Wordsmith. Brain-tickler. Based in Toronto, Ontario.{" "}
      </p>
    </div>
  );
}
