/* eslint-disable no-use-before-define */
import React from "react";
import AssetFetcher from "../helpers/AssetFetcher";

export default function About() {
  return (
    <div class="w-2/3 mx-10">
      <p>
        In recognizing what a powerful tool literary expression can be, I’ve
        spent years learning how to polish up every aspect of my work—from
        grammar and spelling, to precision and structure, to clarity and
        proofreading, I’ve learned to touch others with my work (and conquered
        the mechanics of effective writing along the way).
      </p>
      <br />
      <p>
        My literary identity is constantly evolving. Like myself, my work is
        dynamic, fluid, and multifaceted. I like to use my creativity and social
        platforms to create and share meaningful work. I’m not one to shy away
        from addressing bold topics—as Desmond Tutu puts it,
      </p>
      <br />
      <i>
        "If you are neutral in situations of injustice, you have chosen the side
        of the oppressor."
      </i>
      <br />
      <br />
      <p>
        In my free time, you can find me baking, reading, exploring, or
        maintaining my five-star island on Animal Crossing!
      </p>
      <div>
        <div className="flex flex-col justify-center my-5">
          <div className="w-1/2 flex flex-col align-end">
            <img
              src={AssetFetcher.ANIMAL_CROSSING}
              alt="Animal Crossing Island"
            />
            <p className="text-right">🎮 JESSTOPIA</p>
          </div>
          <div className="w-1/2 flex flex-col align-end">
            <img src={AssetFetcher.YOSEMITE} alt="Yosemite" />
            <p className="text-right">📍 Yosemite Falls</p>
          </div>
        </div>
      </div>
    </div>
  );
}
