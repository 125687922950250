import WHO from "../assets/WHO.png";
import waterbottle from "../assets/waterbottle.png";
import cafe from "../assets/cafe.png";
import cube from "../assets/cube.png";
import kids_soccer from "../assets/kids_soccer.png";
import motorcycle from "../assets/motorcycle.png";
import question from "../assets/question.png";
import robot from "../assets/robot.png";
import yosemite from "../assets/yosemite.png";
import animal_crossing from "../assets/AC.png";
import metaverse from "../assets/metaverse.png";
import formula_e from "../assets/formula_e.png";

export default Object.freeze({
  WHO: WHO,
  WATER_BOTTLE: waterbottle,
  CAFE: cafe,
  CUBE: cube,
  KIDS_SOCCER: kids_soccer,
  MOTORCYCLE: motorcycle,
  QUESTION: question,
  ROBOT: robot,
  YOSEMITE: yosemite,
  ANIMAL_CROSSING: animal_crossing,
  METAVERSE: metaverse,
  FORMULA_E: formula_e,
});
