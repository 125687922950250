import React from "react";

/*
<GridPhoto 
	imgSrc=imgSrc
  overlayText=overlayText
  /> 
*/
export default function GridPhoto(props) {
  return (
    <div class="container">
      <img class="grid-photo" src={props.imgSrc}></img>
      <div class="overlay">
        <div class="text">{props.overlayText}</div>
      </div>
    </div>
  );
}
